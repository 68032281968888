import styled from "styled-components";

type HeaderContainerProps = {
  hide: boolean;
};

export const HeaderContainer = styled.div<HeaderContainerProps>`
  display: ${(props) => (props.hide ? "none" : "flex")};
  position: fixed;
  top: 0.5rem; /* 0.5*16 = 8px */
  width: 100%;
  max-width: 80rem; /* 80*16 = 1280px */
  height: 5rem; /* 5*16 = 80px */
  border-radius: 0.5rem; /* 0.5*16 = 8px */
  background: ${(props) => props.theme.colors.backgroundSecondary};
  align-items: center;
  justify-content: space-between;
  padding: 2rem;
  & > img {
    width: 4rem; /* 3*16 = 48px */
    height: 4rem; /* 3*16 = 48px */
  }
  & h1,
  h2 {
    font-size: 1.2rem;
    margin: 0;
    padding: 0;
  }
  & h2 {
    font-size: 1rem;
    margin: 0.1rem 0;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  & > h1, > h2 {
    text-align: left;
  }
`;
