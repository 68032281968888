import styled from "styled-components";

type LinkProps = {
  margin?: string;
};

export const Link = styled.a.attrs(props => ({
  target: '_blank',
  rel: 'noreferer noopener'
}))<LinkProps>`
  margin: ${(props) => (props.margin ? props.margin : "1rem 0")};
  font-size: inherit;
  font-weight: ${(props) => props.theme.fontWeights.semibold};
  color: ${(props) => props.theme.colors.primary};
  text-decoration: none;
  text-align: center;
`;
