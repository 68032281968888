import { Drawable } from "roughjs/bin/core";

export type Element = {
  id: number;
  color: string;
  initX: number;
  initY: number;
  finalX: number;
  finalY: number;
  tool: Tool;
  drawElement: Drawable;
  options: {
    color: string;
    fillStyle: FillStyle;
    precisionStyle: PrecisionStyle;
  };
};

export type Tool = "none" | "line" | "rect" | "circle" | "triangle";

export type Action = "none" | "drawing" | "moving";

export type FillStyle =
  | "solid"
  | "zigzag"
  | "hachure"
  | "cross-hatch"
  | "dots"
  | "sunburst"
  | "dashed"
  | "zigzag-line";

export type PrecisionStyle = "precise" | "scribble";

export abstract class iCanvasHelper {
  abstract isWithinElement(x: number, y: number, element: Element): boolean;

  abstract getElementByPosition(
    x: number,
    y: number,
    elements: Element[]
  ): Element | undefined;

  abstract randomColor(): string;

  abstract createElement(
    tool: Tool,
    initX: number,
    initY: number,
    finalX: number,
    finalY: number,
    id: number,
    options: {
      color: string;
      fillStyle: FillStyle;
      precisionStyle?: PrecisionStyle;
    }
  ): Element;

  abstract updateElement(
    element: Element,
    clientX: number,
    clientY: number
  ): Element;
}
