import { BrowserRouter } from "react-router-dom";
import { Theme } from "./components/Theme";
import { AppRoutes } from "./routes";

function App() {
  return (
    <>
      <BrowserRouter>
        <Theme>
          <AppRoutes/>
        </Theme>
      </BrowserRouter>
    </>
  );
}

export default App;
