import { ThemeProvider } from "styled-components";
import { theme } from "../../theme";

type ThemeProps = {
  children: React.ReactElement;
}

export const Theme: React.FC<ThemeProps> = ({ children }) => (
  <ThemeProvider theme={theme}>{children}</ThemeProvider>
);
