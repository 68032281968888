import styled from "styled-components";

type ToolboxProps = {
  active?: boolean;
};

export const Tools = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  width: 100%;
  max-width: 80rem; /* 80*16 = 1280px */
  position: fixed;
  bottom: 2.4rem;
`;

export const Toolbox = styled.div<ToolboxProps>`
  display: ${(props) => (props.active ? "flex" : "none")};
  align-items: center;
  justify-content: space-around;
  background: ${(props) => props.theme.colors.backgroundSecondary};
  border-radius: 1rem;
  z-index: 100;
  padding: 0.4rem 0.2rem;
`;

type ToolboxPopupProps = {
  active?: boolean;
};

export const ToolboxPopup = styled.div<ToolboxPopupProps>`
  display: ${(props) => (props.active ? "flex" : "none")};
  background: ${(props) => props.theme.colors.backgroundSecondary};
  border-radius: 1rem;
  padding: 1rem 0.4rem;
  position: absolute;
  bottom: 4.4rem;
  align-items: center;
  justify-content: space-around;
`;

export const Footer = styled.div`
  position: fixed;
  bottom: 0;
  > h2 {
    text-align: left;
  }
`;

type ToolButtonProps = {
  background?: string;
};

export const ToolButton = styled.button<ToolButtonProps>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: none;
  color: ${(props) => props.theme.primary};
  background-color: ${(props) => props.theme.colors.background};
  padding: 0.8rem;
  border-radius: 0.4rem;
  margin: 0 0.4rem;
  width: 3.4rem;
  height: 3.4rem;
  cursor: pointer;
  & svg {
    width: 1rem;
    height: 1rem;
  }
  font-size: 0.7rem;
  border: 3px solid
    ${(props) => props.background || props.theme.colors.background};
`;

interface CanvaProps {
  drawing?: boolean;
}

export const Canva = styled.canvas<CanvaProps | any>`
  cursor: ${(props) => (props.drawing ? "crosshair" : "default")};
`;
