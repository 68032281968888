import { Button } from "../components/Button";
import { Container } from "../components/Container";
import { Text } from "../components/Text";

export const NotFound: React.FC = () => {
  return (
    <Container error>
      <Text variant='title' >404</Text>
      <Text variant='paragraph' >
        Página não encontrada
      </Text>
      <Button error toPath="/">Voltar a página principal</Button>
    </Container>
  );
}