import styled from "styled-components";

export const Input = styled.input`
  padding: 1rem;
  border: none;
  background: ${props => props.theme.colors.backgroundSecondary};
  color: ${props => props.theme.colors.text};
  font-size: ${props => props.theme.fontSizes.small};
  border-radius: 0.5rem;
  margin: 0.5rem 0;
  width: 80%;
`;