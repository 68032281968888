import { PrecisionStyle } from "src/interfaces/canvas.interface";
import rough from "roughjs/bin/rough"; // lib para manipular o canvas
import {
  Element,
  FillStyle,
  iCanvasHelper,
  Tool,
} from "src/interfaces/canvas.interface";

const generator = rough.generator();

export class CanvasHelper extends iCanvasHelper {
  createElement(
    tool: Tool,
    initX: number,
    initY: number,
    finalX: number,
    finalY: number,
    id: number,
    options: {
      color: string;
      fillStyle: FillStyle;
      precisionStyle: PrecisionStyle;
    }
  ): Element {
    const roughness = options.precisionStyle === "precise" ? 0 : 2.5;
    const generators = {
      line: generator.line(initX, initY, finalX, finalY, {
        roughness,
        stroke: options.color,
      }),
      rect: generator.rectangle(initX, initY, finalX - initX, finalY - initY, {
        fill: options.color,
        fillStyle: options.fillStyle,
        stroke: options.color,
        roughness,
        // bowing: 6,
      }),
      circle: generator.circle(initX, initY, 2 * (finalY - initY), {
        fill: options.color,
        fillStyle: options.fillStyle,
        stroke: options.color,
        roughness,
        strokeLineDash: [0],
      }),
      triangle: generator.polygon(
        [
          [initX, initY],
          [initX + (finalY - initY), finalY],
          [initX - (finalY - initY), finalY],
        ],
        {
          fill: options.color,
          fillStyle: options.fillStyle,
          stroke: options.color,
          roughness,
        }
      ),
    };

    const drawElement = generators[tool as Exclude<Tool, "none">];

    return {
      id,
      color: options.color,
      initX,
      initY,
      finalX,
      finalY,
      tool,
      drawElement,
      options,
    };
  }

  updateElement(
    { initX, initY, tool, id, options }: Element,
    clientX: number,
    clientY: number
  ): Element {
    const element = this.createElement(
      tool,
      initX,
      initY,
      clientX,
      clientY,
      id,
      options
    );

    return element;
  }

  isWithinElement(x: number, y: number, element: Element): boolean {
    const { tool, initX, initY, finalX, finalY } = element;
    if (tool === "rect") {
      const minX = Math.min(initX, finalX);
      const maxX = Math.max(initX, finalX);
      const minY = Math.min(initY, finalY);
      const maxY = Math.max(initY, finalY);
      return x >= minX && x <= maxX && y <= minY && y >= maxY;
    }

    if (tool === "circle") {
      const distance = (x1: number, y1: number, x2: number, y2: number) => {
        return Math.sqrt((x2 - x1) ^ (2 + (y2 - y1)) ^ 2);
      };
      const radius = distance(initX, initY, finalX, finalY);
      return distance(initX, x, initY, y) <= radius;
    }

    if (tool === "line") {
      type Point = { x: number; y: number };
      const distance = (a: Point, b: Point) =>
        Math.sqrt(Math.pow(a.x - b.x, 2) + Math.pow(a.y - b.y, 2));
      const lineInit = { x: initX, y: initY };
      const lineEnd = { x: finalX, y: finalY };
      const point = { x, y };
      const offset =
        distance(lineInit, lineEnd) -
        (distance(lineInit, point) + distance(lineEnd, point));
      return Math.abs(offset) < 1;
    }
    return false;
  }

  getElementByPosition(
    x: number,
    y: number,
    elements: Element[]
  ): Element | undefined {
    return elements.find((element) => this.isWithinElement(x, y, element));
  }

  randomColor(): string {
    const colors = [
      "",
      "#f23",
      "#56f",
      "#f93",
      "#0d6",
      "#5fe",
      "#321",
      "#09f",
      "#347",
    ];
    const index = Math.floor(Math.random() * (0 + colors.length));
    return colors[index];
  }
}
