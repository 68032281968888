const isAuthenticated = () => {
  const user = localStorage.getItem('@diario-digital:user');
  return user || false;
}

const authenticate = (name: string) => {
  localStorage.setItem('@diario-digital:user', name);
  return isAuthenticated();
}

const logout = () => {
  localStorage.removeItem('@diario-digital:user');
}

export { 
  isAuthenticated,
  authenticate,
  logout
}