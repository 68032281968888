import styled from "styled-components";

type ButtonProps = {
  error?: boolean;
}

export const StyledButton = styled.button<ButtonProps>`
  padding: 1rem;
  border: none;
  background: ${props =>  props.error ? props.theme.colors.warning : props.theme.colors.primary};
  color: ${props => props.theme.colors.buttonText};
  border-radius: 0.4rem;
  margin: 0.2rem 0;
  text-transform: uppercase;
  font-weight: 600;
  width: 80%;
  transition: 0.4s all;
  :hover {
    cursor: pointer;
    background: ${props => props.error ? props.theme.colors.warning : props.theme.colors.secondary};
  }
`;