import { Button } from "../components/Button";
import { Container } from "../components/Container";
import { Input } from "../components/Input";
import { Text } from "../components/Text";
import Logo from '../assets/logo.svg';
import { authenticate, isAuthenticated } from "../services/auth";
import { useEffect, useState } from "react";
import { Link } from "../components/Link";

export const Login: React.FC = () => {
  const [name, setName] = useState('');

  useEffect(() => {
    if(isAuthenticated()) {
      window.location.href = '/'
    }
  }, []);

  const handleLogin = () => {
    console.log('log in');
    if(authenticate(name)) {
      window.location.href = '/'
    }
  }

  return (
    <Container>
      <img src={Logo} width='120' alt=''/>
      <Text variant='subtitle'>
        Para continuar precisamos<br/>
        saber como você se chama
      </Text>
      <Input 
        placeholder="Digite seu nome" 
        value={name} 
        onChange={(e: any) => setName(e.target.value)}
      />
      <Button type="submit" onClick={handleLogin}>Acessar</Button>
      
      <Text variant="smallparagraph">
        Copyright © 2022 · Desenvolvido por 
        <Link 
          href='https://asciiej.com.br' 
          title='ASCII Soluções em Tecnologia' 
          margin='1rem 0.2rem'>
            ASCII
        </Link>
      </Text>
    </Container>
  );
}