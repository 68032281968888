export const theme = {
  colors: {
    primary: "#0099DD",
    secondary: "#0099FF",
    error: "#FF2233",
    warning: "#FF9933",
    text: "#121212",
    buttonText: "#FFFFFF",
    background: "#F3F3F3",
    backgroundSecondary: "#D3D3D3",
    backgroundCard: "#E3E3E3"
  },
  fonts: ["sans-serif", "Inter"],
  fontSizes: {
    xsmall: "0.7rem",
    small: "1rem",
    medium: "1.2rem",
    large: "1.5rem"
  },
  fontWeights: {
    bold: "700",
    semibold: "600",
    medium: "500",
    regular: "400"
  }
}
