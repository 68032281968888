import styled from "styled-components";

type VariantProps = {
  margin?: string;
  align?: string;
};

export const Paragraph = styled.p<VariantProps>`
  ${props => props.align && 'position: absolute; bottom: 0; left: 1rem;'}
  text-align: ${(props) => props.align || 'center'};
  margin: ${(props) => (props.margin ? props.margin : "1rem 0")};
  font-size: ${(props) => props.theme.fontSizes.small};
  font-weight: ${(props) => props.theme.fontWeights.regular};
  color: ${(props) => props.theme.colors.text};
`;

export const SmallParagraph = styled.p<VariantProps>`
  ${props => props.align && 'position: absolute; bottom: 0; left: 1rem;'}
  text-align: ${(props) => props.align || 'center'};
  margin: ${(props) => (props.margin ? props.margin : "1rem 0")};
  font-size: ${(props) => props.theme.fontSizes.xsmall};
  font-weight: ${(props) => props.theme.fontWeights.regular};
  color: ${(props) => props.theme.colors.text};
`;

export const Title = styled.h1<VariantProps>`
  ${props => props.align && 'position: absolute; bottom: 0; left: 1rem;'}
  text-align: ${(props) => props.align || 'center'};
  margin: ${(props) => (props.margin ? props.margin : "1rem 0")};
  font-size: ${(props) => props.theme.fontSizes.large};
  font-weight: ${(props) => props.theme.fontWeights.bold};
  color: ${(props) => props.theme.colors.text};
`;

export const Subtitle = styled.h2<VariantProps>`
  ${props => props.align && 'position: absolute; bottom: 0; left: 1rem;'}
  text-align: ${(props) => props.align || 'center'};
  margin: ${(props) => (props.margin ? props.margin : "1rem 0")};
  font-size: ${(props) => props.theme.fontSizes.medium};
  font-weight: ${(props) => props.theme.fontWeights.semibold};
  color: ${(props) => props.theme.colors.text};
`;
