import styled from 'styled-components';

type ContainerProps = {
  error?: boolean;
  fullscreen?: boolean;
}

export const Container = styled.div<ContainerProps>`
  background: ${props => props.fullscreen ? 'transparent' : props.theme.colors.background};
  box-shadow: 0.1rem 0.1rem 2rem ${props => props.theme.colors.text};
  border: none;
  border-top: 0.8rem solid ${props =>  props.error ? props.theme.colors.warning : props.fullscreen ? 'none' : props.theme.colors.primary};
  padding: 1rem 0.4rem;
  width: 100%;
  height: 100%;
  border-radius: 0.8rem;
  ${props => props.fullscreen 
    ? `width: 100vw; height: 100vh; border-radius: 0;` 
    : `min-width: 23rem;  max-width: 25rem; min-height: 15rem; max-height: 25rem;`
  }
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;