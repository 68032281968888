import { useEffect, useLayoutEffect, useState } from "react";
import rough from "roughjs/bin/rough"; // lib para manipular o canvas
import { SketchPicker } from 'react-color';
import { 
  BackgroundColor,
  ClearFormat,
  ColorFilter, 
  Drag, 
  Export,
  GraphicDesign, 
  GridNine, 
  GridSixteen, 
  HashtagKey, 
  Minus, 
  MoveOne,
  PaintedScreen, 
  Pencil, 
  PictureOne, 
  RectangleOne, 
  Redo, 
  Ripple, 
  Round,
  Sunset,
  Texture,
  Triangle,
  Undo,
} from '@icon-park/react';
import { Canva, Footer, Toolbox, ToolboxPopup, ToolButton, Tools } from "./styles";
import { Text } from '../Text';
import { Link } from '../Link';
import { Action, Tool, Element, FillStyle, PrecisionStyle } from "src/interfaces/canvas.interface";
import { CanvasHelper } from "src/helpers/canvas.helper";


type CanvasProps = {
  active?: boolean;
}

const canvasHelper = new CanvasHelper();

const useCanvasHistory = (initialState: Element[]) => {
  const [index, setIndex] = useState(0);
  const [history, setHistory] = useState<Element[][]>([initialState]);

  const setState = (action: any, overwrite = false) => {
    console.log(history);
    const newState = typeof action === 'function' ? action(history[index]) : action;
    if(overwrite) {
      const historyCopy = [...history];
      historyCopy[index] = newState;
      setHistory(historyCopy);
    }else {
      const updatedState = [...history].slice(0, index + 1);
      setHistory([...updatedState, newState]);
      setIndex(prevState => prevState + 1);
    }
    console.log(history);
  }

  const undo = () => index > 0 && setIndex(prevState => prevState - 1);
  const redo = () => index < history.length - 1 && setIndex(prevState => prevState + 1);

  return [
    history[index], 
    setState,
    undo,
    redo
  ] as any;// as [history: Element[], setState: React.Dispatch<React.SetStateAction<Element[]>> | ((elements: Element[], overwrite?: boolean) => void)];
}

const Canvas:React.FC<CanvasProps> = ({ active }) => {
  const [elements, setElements, undo, redo] = useCanvasHistory([]);
  const [movingElement, setMovingElement] = useState<Element>();
  const [action, setAction] = useState<Action>('none');
  const [tool, setTool] = useState<Tool>('none');
  const [fillStyle, setFillStyle] = useState<FillStyle>('solid');
  const [precisionStyle, setPrecisionStyle] = useState<PrecisionStyle>('precise');
  const [color, setColor] = useState<string>('');
  const [backgroundColor, setBackgroundColor] = useState<string>('#FFFFFFa0');
  const [exportImage, setExportImage] = useState<string>('');
  const [showFillStylePopup, setShowFillStylePopup ] = useState(false);
  const [showShapesPopup, setShowShapesPopup ] = useState(false);
  const [showColorPicker, setShowColorPicker ] = useState(false);
  const [showBackgroundColorPicker, setShowBackgroundColorPicker ] = useState(false);
  const [showPrecisionStylePopup, setShowPrecisionStylePopup ] = useState(false);

  useLayoutEffect(()=> {
    const canvas = document.querySelector("#canvas") as HTMLCanvasElement;

    const context = canvas.getContext('2d');
    context?.clearRect(0,0,canvas.width, canvas.height);

    const element = canvasHelper.createElement('rect', 0, 0, canvas.width, canvas.height, 0, {
      color: backgroundColor,
      fillStyle: 'solid',
      precisionStyle: 'precise'
    });

    const roughCanvas = rough.canvas(canvas);
    roughCanvas.draw(element.drawElement);

    elements.forEach(({drawElement}: any) => 
      roughCanvas.draw(drawElement)
    );

    const dataUrl = canvas.toDataURL("image/png");
    if(elements.length) {
      setExportImage(dataUrl);
    }else {
      setExportImage('');
    }
  }, [backgroundColor, elements]); 


  useEffect(() => {
    const undoRedoFuncionality = (event: KeyboardEvent) => {
      if((event.metaKey || event.ctrlKey) && event.key === 'z') {
        if(event.shiftKey) {
          redo();
        }else {
          undo();
        }
      }
    }
    document.addEventListener('keydown', undoRedoFuncionality);

    return () => {
      document.removeEventListener('keydown', undoRedoFuncionality);
    }
  }, [undo, redo]);

  const handleMouseDown = (event: MouseEvent) => {
    const { clientX, clientY } = event;
    if(tool === 'none') {
      const element = canvasHelper.getElementByPosition(clientX, clientY, elements);
      if(element) {
        setAction('moving');
        setMovingElement(element);
        console.log(movingElement)
      }
    }else {
      setAction('drawing');
      const element = canvasHelper.createElement(tool, clientX, clientY, clientX, clientY, elements.length, {
        color,
        fillStyle,
        precisionStyle
      });
      setElements((state: any) => [...state, element]);
      console.log(elements);
    }
  };

  const handleMouseMove = (event: MouseEvent) => {
    const { clientX, clientY } = event;

    const actions = {
      none: () => {},
      drawing: () => {
        const index = elements.length - 1;
        const element = elements[index];
        const updatedElement = canvasHelper.updateElement(element, clientX, clientY);
        const newElements = [...elements];
        newElements[updatedElement.id] = updatedElement;
        setElements(newElements, true);
      },
      moving: () => {},
    }

    actions[action](); 
  };
  
  const handleMouseUp = () => {
    setAction('none');
  };

  const handleCleanCanvas = () => {
    setElements([]);
  }

  const handleDownloadImage = () => {
    if(!elements.length) return;
    const anchor = document.createElement("a");
    anchor.href = exportImage;
    anchor.download = 'diário';
    anchor.click();
    anchor.remove();
  }

  const handleShowBackgroundColorPicker = () => {
    setShowBackgroundColorPicker(!showBackgroundColorPicker);
    setShowColorPicker(false);
    setShowShapesPopup(false); 
    setShowPrecisionStylePopup(false);
    setShowFillStylePopup(false);
  }
  const handleShowColorPicker = () => {
    setShowColorPicker(!showColorPicker);
    setShowBackgroundColorPicker(false);
    setShowShapesPopup(false); 
    setShowPrecisionStylePopup(false);
    setShowFillStylePopup(false);
  }
  const handleShowShapesPopup = () => {
    setShowColorPicker(false);
    setShowBackgroundColorPicker(false);
    setShowShapesPopup(!showShapesPopup); 
    setShowPrecisionStylePopup(false);
    setShowFillStylePopup(false);
  }
  const handleShowFillStylePopup = () => {
    setShowColorPicker(false);
    setShowBackgroundColorPicker(false);
    setShowShapesPopup(false); 
    setShowPrecisionStylePopup(false);
    setShowFillStylePopup(!showFillStylePopup);
  }
  const handleShowPrecisionStylePopup = () => {
    setShowBackgroundColorPicker(false);
    setShowColorPicker(false);
    setShowShapesPopup(false); 
    setShowPrecisionStylePopup(!showPrecisionStylePopup);
    setShowFillStylePopup(false);
  }

  return (
    <>
      <Tools>
        <Toolbox active={active}>
          <ToolboxPopup active={showPrecisionStylePopup}>
            <ToolButton onClick={() =>setPrecisionStyle('scribble')} title="rabisco">
              <Pencil/>
              rabisco
            </ToolButton>
            <ToolButton onClick={() =>setPrecisionStyle('precise')} title="preciso">
            <Minus/>
            preciso  
            </ToolButton>
          </ToolboxPopup>

          <ToolboxPopup active={showColorPicker}>
            <SketchPicker
              color={color}
              presetColors={['red', 'green', 'blue', 'yellow', '#f56', '#f96', '#65f', '#0f9', '#09f']}
              onChangeComplete={newColor => setColor(newColor.hex)}
            />
          </ToolboxPopup>


          <ToolboxPopup active={showFillStylePopup}>
            <ToolButton onClick={() =>setFillStyle('solid')} title="sólido">
              <PaintedScreen />
              sólido
            </ToolButton>
            <ToolButton onClick={() =>setFillStyle('zigzag')} title="zigue zague">
            <Ripple/>
            zigzag
            </ToolButton>
            <ToolButton onClick={() =>setFillStyle('dots')} title="pontos">
              <Drag/>
              pontos
            </ToolButton>
            <ToolButton onClick={() =>setFillStyle('hachure')} title="hachura">
              <GridNine/>
              hachura
            </ToolButton>
            <ToolButton onClick={() =>setFillStyle('cross-hatch')} title="hachura cruzada">
              <GridSixteen/>
              cruzada
            </ToolButton>
            <ToolButton onClick={() =>setFillStyle('dashed')} title="tracejado">
              <HashtagKey/>
              traços
            </ToolButton>
          </ToolboxPopup>

          <ToolboxPopup active={showShapesPopup}>
            <ToolButton onClick={() =>setTool('line')} title="linha">
              <Minus/>
              linha
            </ToolButton>
            <ToolButton onClick={() =>setTool('rect')} title="retangulo">
              <RectangleOne/>
              retângulo
            </ToolButton>
            <ToolButton onClick={() =>setTool('circle')} title="circulo">
              <Round/>
              circulo
            </ToolButton>
            <ToolButton onClick={() =>setTool('triangle')} title="triangulo">
              <Triangle/>
              triângulo
            </ToolButton>
          </ToolboxPopup>

          <ToolButton onClick={() => setTool('none')} title="nenhum">
            <MoveOne/>
            nenhum
          </ToolButton>
          <ToolButton onClick={handleShowPrecisionStylePopup} title="precisão">
            <Sunset/>
            precisão
          </ToolButton>
          <ToolButton onClick={handleShowShapesPopup} title="formas">
            <GraphicDesign/>
            formas
          </ToolButton>
          <ToolButton onClick={handleShowFillStylePopup} title="preenchimento">
            <Texture/>
            preencher
          </ToolButton>
          <ToolButton onClick={handleShowColorPicker} title="cor" background={color}>
            <ColorFilter/>
            cores
          </ToolButton>
          
        </Toolbox>

        <Toolbox active={active}>
          <ToolboxPopup active={showBackgroundColorPicker}>
            <SketchPicker
              color={backgroundColor}
              presetColors={['transparent', 'red', 'green', 'blue', 'yellow', '#f56', '#f96', '#65f', '#0f9', '#09f']}
              onChangeComplete={newColor => setBackgroundColor(newColor.hex)}
            />
          </ToolboxPopup>
          
          <ToolButton onClick={handleShowBackgroundColorPicker} title="fundo">
            <BackgroundColor/>
            fundo
          </ToolButton>
          {/* <ToolButton onClick={handleShowBackgroundColorPicker} title="fundo">
            <PictureOne/>
            imagem
          </ToolButton>  */}
        </Toolbox>

        <Toolbox active={active}>
          <ToolButton onClick={handleDownloadImage} title="exportar">
            <Export/>
            salvar
          </ToolButton>
          <ToolButton onClick={handleCleanCanvas} title="limpar tudo">
            <ClearFormat/>
            limpar
          </ToolButton>
          <ToolButton onClick={undo} title="desfazer">
            <Undo/>
            desfazer
          </ToolButton>
          <ToolButton onClick={redo} title="refazer">
            <Redo/>
            refazer
          </ToolButton>
        </Toolbox>
      </Tools>

      <Footer>
        <Text variant="smallparagraph">
          Copyright © 2022 · Desenvolvido por 
          <Link 
            href='https://asciiej.com.br' 
            title='ASCII Soluções em Tecnologia' 
            margin='1rem 0.2rem'>
              ASCII
          </Link>
        </Text>
      </Footer>

      <Canva 
        id="canvas"
        drawing={tool !== 'none' || action === 'drawing'}
        style={{background: backgroundColor}}
        width={window.innerWidth}
        height={window.innerHeight}
        onMouseDown={handleMouseDown}
        onMouseUp={handleMouseUp}
        onMouseMove={handleMouseMove}
      />

      <Text variant="paragraph" align='left'>
        Aperte F2 para mostrar/esconder o layout  
      </Text>   
    </>
  );
}

export { Canvas };
