import { useEffect, useState } from "react";
import { Canvas } from "../components/Canva";
import { Container } from "../components/Container";
import { Header } from "../components/Header";
import { isAuthenticated } from "../services/auth";

export const Home: React.FC = () => {
  const [name, setName] = useState('');
  const [showLayout, setShowLayout] = useState(true);

  useEffect(() => {
    const user = isAuthenticated();
    if(!user) {
      window.location.href = '/login'
      return;
    }
    setName(user);
  }, []);

  useEffect(() => {
    window.addEventListener('keydown', (event) => {
      if(event.key === 'F2') {
        setShowLayout(!showLayout);
      }
    });
  }, [showLayout]);

  return (
    <Container fullscreen>
      <Header user={name} hide={!showLayout}/>
      <Canvas active={showLayout}/>
    </Container>
  )
}
