import { Content, HeaderContainer } from "./styles";
import { Text } from '../Text';
import { logout } from "../../services/auth";

type HeaderProps = {
  user: string;
  hide: boolean;
}

export const Header: React.FC<HeaderProps> = ({user, hide}) => {
  const handleLogout = () => {
    logout()
    window.location.href= "/login"
  }
  return ( 
    <HeaderContainer hide={hide}>
      <Content>
        <Text variant='title' >Diário de Ideias</Text>
        <Text variant='subtitle' >Digital</Text>
      </Content>
      <Content>
        <Text variant="subtitle" >Diário de {user}</Text>
      </Content>
      <Content>
        <Text variant='subtitle' onClick={handleLogout}>Logout</Text>
      </Content>
    </HeaderContainer>
  )
};