import { Title, Subtitle, Paragraph, SmallParagraph } from './variants';

const Variants = {
  'title': Title,
  'subtitle': Subtitle,
  'paragraph': Paragraph,
  'smallparagraph': SmallParagraph,
}

type TextProps = {
  align?: string;
  margin?: string;
  variant: 'title' | 'subtitle' | 'paragraph' | 'smallparagraph' 
  children: any,
  onClick?: () => void
}

export const Text: React.FC<TextProps> = ({ align, margin, variant, children, onClick }) => {
  const Variant = Variants[variant];
  return (
    <Variant align={align} margin={margin} onClick={onClick} children={children} />
  );
}