import React, { ButtonHTMLAttributes } from "react"
import { StyledButton } from "./styles"

type ButtonProps = {
  toPath?: string;
  error?: boolean;
} & ButtonHTMLAttributes<any>;

export const Button: React.FC<ButtonProps> = ({ toPath, children, error, onClick }) => {

  const handleClick = (e: any) => {
    if(toPath) {
      window.location.href = toPath;
    }
    if(onClick) {
      onClick(e);
    }
  }
  return <StyledButton error={error} onClick={(e) => {handleClick(e);}}>{children}</StyledButton>
}